define('vi-di/models/package-rule', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    title: _emberData['default'].attr('string'),

    roomPackage: _emberData['default'].belongsTo('RoomPackage', { async: true }),

    category: _emberData['default'].belongsTo('Category', { async: true }),
    material: _emberData['default'].belongsTo('Material', { async: true }),

    packageRuleType: _emberData['default'].belongsTo('PackageRuleType', { async: true })

  });
});