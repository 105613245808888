define('vi-di/models/category', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    title: _emberData['default'].attr('string'),
    room: _emberData['default'].belongsTo('Room', { async: true }),
    materials: _emberData['default'].hasMany('Material', { async: true }),
    depth: _emberData['default'].attr('number'),
    sortOrder: _emberData['default'].attr('number'),
    isSelected: _emberData['default'].attr('boolean', { defaultValue: false }),

    isLastItem: (function () {
      return this.get('sortOrder') >= this.get('room').get('categories').get('length') - 1;
    }).property('sortOrder'),

    currentMaterial: (function () {
      return this.get('materials').filterBy('isSelected').get('firstObject');
    }).property('materials.content.@each.isSelected')

  });
});