define('vi-di/components/rooms/room-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ["menu-items"],
    classNameBindings: ['isSelected:menuItemSelected'],

    isSelected: false,

    click: function click() {
      var room = this.get('room');
      this.sendAction('action', room);
    }

  });
});
/*
  didInsertElement() {
    this.renderChildTooltips();
  },

  showToolTip: Ember.observer('room.floorplan.salescenter.showToolTips', function() {
      var showToolTipsVal = this.get('room').get('floorplan').get('salescenter').get('showToolTips');
      if( showToolTipsVal >= 1 ){
        //show tool tip
        if( this.get('tooltip') ){
          this.get('tooltip').show();

          var me = this;
          //reinitalize slick
          Ember.run.later((function() {
            me.get('tooltip').hide();
          }), 3000);

        }
      }
  })*/