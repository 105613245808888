define('vi-di/components/floorplan-view', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: [''],

    actions: {

      onRoomSelection: function onRoomSelection(selectedRoom) {
        console.log('onRoomSelection');

        if (selectedRoom.get('isSelected')) {
          return;
        }

        this.sendAction('roomTransition', selectedRoom.get('id'));
      }

    }, // end actions

    click: function click() {
      var materialId = this.get('materialId');
      this.sendAction('action', materialId);
    },

    showToolTipTriggered: _ember['default'].observer('this.model.salescenter.showToolTip', function () {
      console.log("SHOW TOOL TIP");

      this.get('tooltip').show();
      var me = this;

      _ember['default'].run.later(function () {
        me.get('tooltip').hide();
      }, 3000);
    }),

    currentRooms: (function () {
      //  console.log('currentRooms');
      var rooms = this.model.get('rooms');
      rooms = rooms.toArray();
      var sorted = rooms.sort(function (a, b) {
        return a.get('sortOrder') - b.get('sortOrder');
      });

      return sorted;
    }).property('this.model.rooms'),

    currentRoom: (function () {
      //  console.log('currentRooms');
      var currentRoom = this.model.get('currentRoom');

      return currentRoom;
    }).property('this.model.currentRoom')

  });
});