define('vi-di/models/salescenter', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    company: _emberData['default'].attr('string'),
    name: _emberData['default'].attr('string'),
    logo: _emberData['default'].attr('string'),
    floorplans: _emberData['default'].hasMany('Floorplan', { async: true }),

    showToolTip: _emberData['default'].attr('number', { defaultValue: 0 }),

    currentFloorPlan: (function () {
      return this.get('floorplans').filterBy('isSelected').get('firstObject');
    }).property('floorplans.content.content.@each.isSelected')

  });
});
/*
  currentFloorPlan: function() {
      return this.get('floorplans').filterBy( 'isSelected' ).get('firstObject');
  }.property( 'floorplans.@each.isSelected')
  */