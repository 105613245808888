define('vi-di/initializers/ember-tooltips', ['exports', 'ember', 'vi-di/config/environment', 'vi-di/mixins/components/tooltips'], function (exports, _ember, _viDiConfigEnvironment, _viDiMixinsComponentsTooltips) {
  exports.initialize = initialize;

  /* This is in the app tree so we can access ENV */

  function initialize() {
    var defaultOptions = {
      addTo: ['Component']
    };
    var overridingOptions = _viDiConfigEnvironment['default'].tooltips || {};
    var options = _ember['default'].merge(defaultOptions, overridingOptions);

    /* TODO - Needs test coverage for addTo */

    if (_ember['default'].typeOf(options.addTo) === 'array') {
      options.addTo.forEach(function (className) {
        _ember['default'][className].reopen(_viDiMixinsComponentsTooltips['default']);
      });
    }
  }

  exports['default'] = {
    name: 'ember-tooltips',
    initialize: initialize
  };
});