define('vi-di/routes/salescenter/floorplan/room/category', ['exports', 'ember', 'vi-di/mixins/google-analytics'], function (exports, _ember, _viDiMixinsGoogleAnalytics) {
  exports['default'] = _ember['default'].Route.extend(_viDiMixinsGoogleAnalytics['default'], {

    renderTemplate: function renderTemplate() {
      //        this.render('salescenter.floorplan', { into: 'floorplan' } );
      this.render('salescenter.floorplan.room.category', { into: 'salescenter', outlet: 'categories' });
      this.render('salescenter.floorplan.room.category-overlays', { into: 'salescenter', outlet: 'category-overlays' });

      this.render('salescenter.floorplan.room.category-mobile', { into: 'salescenter', outlet: 'category-mobile' });
    },
    model: function model(params) {
      console.log("INSIDE CATEGORY MODEL ");

      var categoryID = params.category_id;
      console.log("REQUESTING CATEGORY  " + categoryID);
      var storeRef = this;

      var loadingController = this.controllerFor('salescenters-loading');
      loadingController.set('loadingMessage', 'Loading Selections');

      var me = this;

      return this.store.find('category', categoryID).then(function (categories) {
        var materialsPromise = categories.get('materials').toArray();
        return _ember['default'].RSVP.all(materialsPromise).then(function (materialsCollections) {

          //ITeRATE THROUGH MATERIALS AND PRELOAD IMAGES HERE

          if (!me.isInternetExplorer()) {
            materialsCollections.forEach(function (material) {
              me.preloadImages(material);
            }); //end materialsCollections for each
          }
          /*          var materials = materialsCollections.reduce(function(sum, val){
                      return sum.pushObjects(val.toArray());
                    }, []);
                    debugger;
          */

          return categories;
        });
      });
    },

    isInternetExplorer: function isInternetExplorer() {
      if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        // MSIE
        return true;
      }
      return false;
    },

    preloadImages: function preloadImages(material) {
      // seems the new syntax in ECMAScript 6
      //begin preloading images - get image URL

      var HOST_URL = this.store.adapterFor('application').get('baseImageURL');

      var overlayImageURL = HOST_URL + material.get('overlayImage');
      var thumbnailImageURL = HOST_URL + material.get('thumbnailImage');
      var promiseOverlay = new Promise(function (resolve, reject) {
        var image = new Image();
        image.onload = resolve;
        image.onerror = reject;
        image.src = overlayImageURL;
      });
      var promiseThumbnail = new Promise(function (resolve, reject) {
        var image = new Image();
        image.onload = resolve;
        image.onerror = reject;
        image.src = thumbnailImageURL;
      });
      promiseOverlay.then(function (value) {
        // on fulfillment
        //console.log("IMAGE PRELOADED " + value );
      }, function (reason) {
        // on rejection
        console.log("IMAGE REJECTED " + reason);
      });
      promiseThumbnail.then(function (value) {
        // on fulfillment
        //console.log("IMAGE PRELOADED " + value );
      }, function (reason) {
        // on rejection
        console.log("IMAGE REJECTED " + reason);
      });
      //end IMAGE PRELOADING
    },

    afterModel: function afterModel(model, transition) {

      //Track Page Event using Google Analytics
      this.trackPageView("Room - " + model.get('room').get('title') + " - Category - " + model.get('title'));

      //set all categories as false for selectedMaterial
      var room = model.get('room');
      var categories = room.get('categories');
      categories.forEach(function (category) {
        if (category.get('title') != null) {
          category.set('isSelected', false);
        }
      });
      model.set('isSelected', true);

      //  console.log("After Model Category ");
    }

  });
});