define('vi-di/models/floorplan', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    title: _emberData['default'].attr('string'),
    rooms: _emberData['default'].hasMany('Room', { async: true }),
    salescenter: _emberData['default'].belongsTo('Salescenter', { async: true }),
    isSelected: _emberData['default'].attr('boolean'),

    currentRoom: (function () {
      return this.get('rooms').filterBy('isSelected').get('firstObject');
    }).property('rooms.content.@each.isSelected')

  });
});