define('vi-di/models/material', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    title: _emberData['default'].attr('string'),
    category: _emberData['default'].belongsTo('Category', { async: true }),
    overlayImage: _emberData['default'].attr('string'),
    thumbnailImage: _emberData['default'].attr('string'),

    isSelected: _emberData['default'].attr('boolean', { defaultValue: false }),
    isFeatured: _emberData['default'].attr('boolean', { defaultValue: false }),

    sortOrder: _emberData['default'].attr('number'),

    isFirstItem: (function () {
      return this.get('sortOrder') === 0;
    }).property('sortOrder'),
    isLastItem: (function () {
      return this.get('sortOrder') >= this.get('category').get('materials').get('length') - 1;
    }).property('sortOrder')

  });
});