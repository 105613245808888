define("vi-di/templates/components/material-item", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 2
            },
            "end": {
              "line": 6,
              "column": 1
            }
          },
          "moduleName": "vi-di/templates/components/material-item.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("small");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "glyphicon glyphicon-ok selectedMaterial");
          dom.setAttribute(el2, "style", "padding: 3px");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 0
          }
        },
        "moduleName": "vi-di/templates/components/material-item.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("<div class=\"thumbnail\" style=\"height: 100%;\"> ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("   ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "style", "display:block; margin: 0 auto");
        var el3 = dom.createElement("img");
        dom.setAttribute(el3, "style", "display:block; margin: 0 auto; width: 70px; height:70px");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n   ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "caption");
        dom.setAttribute(el2, "style", "margin: 0 auto; padding: 0px;");
        var el3 = dom.createTextNode("\n     ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h5");
        dom.setAttribute(el3, "class", "karlaBold");
        dom.setAttribute(el3, "style", "text-align: center; margin: 0 auto; width:100px; word-wrap: break-word;");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n   ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("</div>\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(element0, [3, 0]);
        var morphs = new Array(5);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createAttrMorph(element1, 'src');
        morphs[3] = dom.createAttrMorph(element1, 'class');
        morphs[4] = dom.createMorphAt(dom.childAt(element0, [5, 1]), 0, 0);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["thumbnail ", ["subexpr", "if", [["get", "isSelected", ["loc", [null, [3, 27], [3, 37]]]], "thumbnailSelected", "thumbnailUnselected"], [], ["loc", [null, [3, 22], [3, 81]]]]]]], ["block", "if", [["get", "isSelected", ["loc", [null, [4, 8], [4, 18]]]]], [], 0, null, ["loc", [null, [4, 2], [6, 8]]]], ["attribute", "src", ["concat", [["get", "hostURL", ["loc", [null, [7, 58], [7, 65]]]], ["get", "thumbnailImage", ["loc", [null, [7, 69], [7, 83]]]]]]], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "isFeatured", ["loc", [null, [7, 99], [7, 109]]]], "itemIsFeatured"], [], ["loc", [null, [7, 94], [7, 128]]]]]]], ["content", "title", ["loc", [null, [9, 107], [9, 116]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});