define('vi-di/routes/salescenter/floorplan/room', ['exports', 'ember', 'vi-di/mixins/google-analytics'], function (exports, _ember, _viDiMixinsGoogleAnalytics) {
      exports['default'] = _ember['default'].Route.extend(_viDiMixinsGoogleAnalytics['default'], {

            renderTemplate: function renderTemplate() {
                  //        this.render('salescenter.floorplan', { into: 'floorplan' } );
                  this.render('salescenter.floorplan.roommobile', { into: 'salescenter', outlet: 'roommobile' });
                  this.render('salescenter.floorplan.room', { into: 'salescenter', outlet: 'room' });
                  this.render('salescenter.floorplan.roompackages', { into: 'salescenter', outlet: 'roompackages' });
            },

            model: function model(params) {
                  //  console.log("INSIDE ROOM MODEL " );

                  var roomID = params.room_id;
                  //  console.log("REQUESTING room  " + roomID);

                  var loadingController = this.controllerFor('salescenters-loading');
                  loadingController.set('loadingMessage', 'Loading Rooms');

                  return this.store.find('room', roomID); /*.then(function(rooms) {
                                                          var categoriesPromise = rooms.get('categories').toArray();
                                                          return Ember.RSVP.all(categoriesPromise).then( function (categoriesCollections) {
                                                          return rooms;
                                                          });
                                                          });*/
            },

            afterModel: function afterModel(model, transition) {
                  //console.log(" ROOM  - performing transition to category ");

                  //Track Page Event using Google Analytics
                  this.trackPageView("Room - " + model.get('title'));

                  //set all rooms to not being selected
                  var rooms = model.get('floorplan').get('rooms');
                  rooms.forEach(function (room) {
                        room.set('isSelected', false);
                  });

                  //if there are room packages and one is not selected, then select one
                  /*
                  if( model.get('roomPackages').get('length') > 0 && model.get('currentPackage') === undefined  ) {
                      model.get('roomPackages').get('firstObject').set('isSelected', true);
                  }
                  */

                  //select current room
                  model.set('isSelected', true);

                  var category = model.get('categories').toArray().get('firstObject');
                  this.transitionTo('salescenter.floorplan.room.category', category.get('id'));
            }

      });
});