define('vi-di/controllers/salescenter/floorplan', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    //  materialComponentIds: [] ,

    init: function init() {
      //  debugger;
    },

    actions: {

      onRoomSelection: function onRoomSelection(selectedRoom) {
        console.log('onRoomSelection');

        if (selectedRoom.get('isSelected')) {
          return;
        }

        this.transitionToRoute('salescenter.floorplan.room', selectedRoom.get('id'));
      },

      onTransitionToRoom: function onTransitionToRoom(roomID) {

        //ANIMATE THE CATEGORIES CONTAINER
        $("#categoriesContainer").animateCSS("fadeOutLeft", {
          duration: 350
        });

        //ANIMATE the Overlays Container
        $("#overlayImagesGroup").animateCSS("fadeOut", {
          duration: 500
        });

        var me = this;
        _ember['default'].run.later(function () {
          me.transitionToRoute('salescenter.floorplan.room', roomID);
        }, 300);
      },

      onClearAllClicked: function onClearAllClicked() {
        console.log('clear all');

        //must unselect all materials
        var rooms = this.model.get('rooms');

        rooms.forEach(function (room) {
          var categories = room.get('categories');

          categories.forEach(function (category) {
            var materials = category.get('materials');

            materials.forEach(function (material) {
              console.log('toggle off material');
              material.set('isSelected', false);
            });
          });
        });
      } },

    //end clear all

    currentRooms: (function () {
      //  console.log('currentRooms');
      var rooms = this.model.get('rooms');
      rooms = rooms.toArray();
      var sorted = rooms.sort(function (a, b) {
        return a.get('sortOrder') - b.get('sortOrder');
      });

      return sorted;
    }).property('this.model.rooms'),

    currentRoom: (function () {
      //  console.log('currentRooms');
      var currentRoom = this.model.get('currentRoom');

      return currentRoom;
    }).property('this.model.currentRoom')

  });
});