define('vi-di/models/room', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    title: _emberData['default'].attr('string'),
    categories: _emberData['default'].hasMany('Category', { async: true }),
    baseImage: _emberData['default'].attr('string'),
    //  isSelected: DS.attr('boolean'),
    floorplan: _emberData['default'].belongsTo('Floorplan', { async: true }),
    sortOrder: _emberData['default'].attr('number'),

    roomPackages: _emberData['default'].hasMany('RoomPackage', { async: true }),

    roomType: _emberData['default'].attr('string'),

    isSelected: _emberData['default'].attr('boolean'),

    currentPackage: (function () {
      return this.get('roomPackages').filterBy('isSelected').get('firstObject');
    }).property('roomPackages.content.@each.isSelected')

  });
});