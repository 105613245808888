define('vi-di/components/material-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['materialItem animated fadeInUp'],
    isLastItem: true,

    click: function click() {
      var materialId = this.get('materialId');
      this.sendAction('action', materialId);
    }
  });
});