define('vi-di/controllers/salescenter', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    hostURL: function hostURL() {
      return this.store.adapterFor('application').get('baseImageURL');
    },

    actions: {

      onShowToolTips: function onShowToolTips() {
        var val = this.model.get('showToolTip');
        this.model.set('showToolTip', val + 1);
      },

      onChangeMaterialTab: function onChangeMaterialTab(tabTitle) {

        if (tabTitle === "Materials") {
          $("#materialsTabPanel").css("display", "block");
          $("#packagesTabPanel").css("display", "none");

          $("#materialsCaret").css("display", "block");
          $("#packagesCaret").css("display", "none");

          $("#packagesLink1").removeClass("active");
          $("#materialsLink1").addClass("active");
        } else if (tabTitle === "Packages") {

          $("#materialsTabPanel").css("display", "none");
          $("#packagesTabPanel").css("display", "block");

          $("#materialsCaret").css("display", "none");
          $("#packagesCaret").css("display", "block");

          $("#materialsLink1").removeClass("active");
          $("#packagesLink1").addClass("active");
        }
      }

    }, // end actions

    logoImageURL: (function () {
      var imageURL = this.hostURL() + this.model.get('logo');
      return imageURL;
    }).property('this.model.logo')

  });
});