define('vi-di/routes/salescenter/floorplan', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    //  needs: ['application'],
    //  isCorrectRouteActive: Ember.computed.equal('controllers.application.currentRouteName', 'correctRoute'),

    renderTemplate: function renderTemplate() {
      //        this.render('salescenter.floorplan', { into: 'floorplan' } );
      this.render('salescenter.floorplan-clearall', { into: 'salescenter', outlet: 'floorplan_clearall' });
      this.render('salescenter.floorplan', { into: 'salescenter', outlet: 'floorplan' });
      this.render('salescenter.floorplan-mobile', { into: 'salescenter', outlet: 'floorplan_mobile' });
    },

    /*
      beforeModel: function(params) {
        console.log("FLOOR PLAN BEFORE MODEL " );
          if(this.store.peekAll('floorplan').get('length') === 0 ) {
          console.log("FLOOR PLAN TRansition To Sales Centers" );
          this.transitionTo('salescenters');
          return;
        }
        }, */

    model: function model(params) {
      console.log("FLoor plan MODEL " + params);

      var floorPlanID = params.floorplan_id;
      console.log("REQUESTING FLOOR PLAN " + floorPlanID);

      var loadingController = this.controllerFor('salescenters-loading');
      loadingController.set('loadingMessage', 'Loading Floor Plan');

      return this.store.find('floorplan', floorPlanID);
    },

    afterModel: function afterModel(model, transition) {
      console.log(" FLOOR PLAN - performing transition to ");

      //set all floorplans to not selected
      var floorplans = model.get('salescenter').get('floorplans');
      floorplans.forEach(function (floorplan) {
        floorplan.set('isSelected', false);
      });

      //select current floorplan
      model.set('isSelected', true);

      var room = model.get('rooms').toArray().get('firstObject');
      this.transitionTo('salescenter.floorplan.room', room.get('id'));
    }

  });
});