define('vi-di/mixins/google-analytics', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    pageHasGa: function pageHasGa() {
      return window.ga && typeof window.ga === "function";
    },

    logTrackingEnabled: function logTrackingEnabled() {
      return !!window.ENV && !!window.ENV.LOG_EVENT_TRACKING;
    },

    logTracking: function logTracking() {
      _ember['default'].Logger.info('Tracking Google Analytics event: ', arguments);
    },

    trackPageView: function trackPageView(page) {
      if (this.pageHasGa()) {
        if (!page) {
          var loc = window.location;
          page = loc.hash ? loc.hash.substring(1) : loc.pathname + loc.search;
        }

        ga('send', 'pageview', page);
      }

      if (this.logTrackingEnabled()) {
        this.logTracking('pageview', page);
      }
    },

    trackEvent: function trackEvent(category, action, label, value) {
      if (this.pageHasGa()) {
        ga('send', 'event', category, action, label, value);
      }

      if (this.logTrackingEnabled()) {
        this.logTracking('event', category, action, label, value);
      }
    }

  });
});