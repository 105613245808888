define('vi-di/router', ['exports', 'ember', 'vi-di/config/environment'], function (exports, _ember, _viDiConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _viDiConfigEnvironment['default'].locationType
  });

  Router.map(function () {

    this.route('salescenter', { path: 'salescenters/:salescenter_id' }, function () {
      this.route('floorplan', { path: 'floorplans/:floorplan_id' }, function () {
        // since we passed a function, index is generated for us
        this.route('room', { path: 'rooms/:room_id' }, function () {
          this.route('category', { path: 'categorys/:category_id' });
        });
        // /
      });
    });
  });

  exports['default'] = Router;
});