define("vi-di/templates/application", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "vi-di/templates/application.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("\n{{#simple-modal enabled=true title=\"--NOTICE--\"}}\nThe Vendor shall have the right to substitute other products and materials for those listed and outlined or provided for in the plans and specifications provided that the substituted materials are of a quality equal to, or better than, the products and materials so listed or so provided.  Colours and specific finishes will depend on Vendor’s package as selected.  All specifications, dimensions and materials are subject to change without notice\n<br/><br/>\nThe Vendor does not warrant that the product descriptions, colours, textures, information or other content available in the digital catalogue are accurate, complete, reliable, current, or error-free.  E. & O.E.\n  {{/simple-modal}}");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n ");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["content", "outlet", ["loc", [null, [9, 1], [9, 11]]]]],
      locals: [],
      templates: []
    };
  })());
});