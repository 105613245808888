define("vi-di/templates/salescenter/floorplan/room/category", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 10
                },
                "end": {
                  "line": 14,
                  "column": 10
                }
              },
              "moduleName": "vi-di/templates/salescenter/floorplan/room/category.hbs"
            },
            isEmpty: true,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 8
              },
              "end": {
                "line": 16,
                "column": 8
              }
            },
            "moduleName": "vi-di/templates/salescenter/floorplan/room/category.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "material-item", [], ["action", "onMaterialsSelected", "title", ["subexpr", "@mut", [["get", "material.title", ["loc", [null, [12, 62], [12, 76]]]]], [], []], "isFeatured", ["subexpr", "@mut", [["get", "material.isFeatured", ["loc", [null, [12, 88], [12, 107]]]]], [], []], "description", ["subexpr", "@mut", [["get", "material.description", ["loc", [null, [12, 120], [12, 140]]]]], [], []], "subdescription", ["subexpr", "@mut", [["get", "material.subdescription", ["loc", [null, [12, 156], [12, 179]]]]], [], []], "price", ["subexpr", "@mut", [["get", "material.price", ["loc", [null, [12, 186], [12, 200]]]]], [], []], "materialId", ["subexpr", "@mut", [["get", "material.id", ["loc", [null, [12, 212], [12, 223]]]]], [], []], "thumbnailImage", ["subexpr", "@mut", [["get", "material.thumbnailImage", ["loc", [null, [12, 239], [12, 262]]]]], [], []], "isSelected", ["subexpr", "@mut", [["get", "material.isSelected", ["loc", [null, [13, 21], [13, 40]]]]], [], []], "isLastItem", ["subexpr", "@mut", [["get", "material.isLastItem", ["loc", [null, [13, 52], [13, 71]]]]], [], []], "hostURL", ["subexpr", "@mut", [["get", "hostURLProperty", ["loc", [null, [13, 80], [13, 95]]]]], [], []]], 0, null, ["loc", [null, [12, 10], [14, 28]]]]],
          locals: ["material"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 6
            },
            "end": {
              "line": 18,
              "column": 6
            }
          },
          "moduleName": "vi-di/templates/salescenter/floorplan/room/category.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "each", [["get", "currentMaterialsToDisplay", ["loc", [null, [11, 16], [11, 41]]]]], [], 0, null, ["loc", [null, [11, 8], [16, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 27,
            "column": 0
          }
        },
        "moduleName": "vi-di/templates/salescenter/floorplan/room/category.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "material_slider");
        dom.setAttribute(el1, "style", "height: 140px; position: relative;");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "id", "materialsPane");
        dom.setAttribute(el2, "style", "width: 100%; margin: 0 auto; height: 100%; bottom: 10px; ");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "id", "slickPrevArrow");
        dom.setAttribute(el3, "class", "slickPrev");
        dom.setAttribute(el3, "style", "");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "id", "carouselContainer");
        dom.setAttribute(el3, "style", "display: inline-block; width: calc(100% - 80px); height: 130px;");
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "id", "slickNextArrow");
        dom.setAttribute(el3, "class", "slickNext");
        dom.setAttribute(el3, "style", "");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" material-slider ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1, 3]), 1, 1);
        return morphs;
      },
      statements: [["block", "slick-carouselslider", [], ["action", "initSlick", "vertical", false, "arrows", true, "slickReinit", ["subexpr", "@mut", [["get", "slickReinit", ["loc", [null, [9, 88], [9, 99]]]]], [], []], "arrows", true, "slidesToShow", ["subexpr", "@mut", [["get", "slidesToShowValue", ["loc", [null, [9, 125], [9, 142]]]]], [], []], "slidesToScroll", 1, "infinite", false, "speed", 300, "nextArrow", ".slickNext", "prevArrow", ".slickPrev"], 0, null, ["loc", [null, [9, 6], [18, 31]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});