define('vi-di/controllers/salescenter/floorplan/room', ['exports', 'ember', 'vi-di/mixins/google-analytics'], function (exports, _ember, _viDiMixinsGoogleAnalytics) {
  exports['default'] = _ember['default'].Controller.extend(_viDiMixinsGoogleAnalytics['default'], {

    hostURL: function hostURL() {
      return this.store.adapterFor('application').get('baseImageURL');
    },

    actions: {
      onCategorySelection: function onCategorySelection(categoryID) {
        console.log('onCategorySelection ' + categoryID);

        //track selected category
        var selectedCategory = this.model.get('categories').findBy('id', categoryID);
        this.trackEvent(this.model.get('title'), "Category", selectedCategory.get('title'));

        this.transitionToRoute('salescenter.floorplan.room.category', categoryID);
      }, //end oNCategorySelection

      onPackageSelected: function onPackageSelected(packageID) {

        console.log("onPackageSelected");

        var roomPackages = this.model.get('roomPackages');
        var selectedPackage = roomPackages.findBy('id', packageID);

        selectedPackage.set('isSelected', true);

        //for the current materials in this category, assign the correct one
        roomPackages.forEach(function (item) {
          if (item.get('id') != packageID) {
            item.set('isSelected', false);
          }
        });

        this.send('applyPackageRules');

        //Track Page Event using Google Analytics
        //      this.trackPageView( "Room - " + this.model.get('title') + " - Package - " + this.model.get('title')  );

        //track selected package
        this.trackEvent(this.model.get('title'), "Package", selectedPackage);
      },

      applyPackageRules: function applyPackageRules() {
        console.log('applying package rules');

        var roomPackage = this.model.get('currentPackage');
        var me = this;
        var packageRules = roomPackage.get('packageRules');

        packageRules.forEach(function (packageRule) {
          if (packageRule.get('packageRuleType').get('type') === 'RULE_SHOW_MATERIAL_BY_DEFAULT') {
            //material enabled when selecting package
            console.log("found package rule");
            me.send('onSelectMaterial', packageRule.get('material'));
          }
        });
      },

      //used by room controller
      onSelectMaterial: function onSelectMaterial(material) {

        var materials = material.get('category').get('materials');

        //for the current materials in this category, assign the correct one
        materials.forEach(function (item) {
          if (item.get('id') === material.get('id')) {
            item.set('isSelected', true);
          } else {
            item.set('isSelected', false);
          }
        });
      }

    }, // end actions

    currentRoomPackages: (function () {
      var roomPackages = this.model.get('roomPackages').toArray();

      if (roomPackages.get('length') === 0) {
        return [];
      }

      var sorted = roomPackages.sort(function (a, b) {
        return a.get('sortOrder') - b.get('sortOrder');
      });

      return sorted;
    }).property('this.model.roomPackages'),

    hasRoomPackages: (function () {
      var numPackages = this.model.get('roomPackages').get('length');
      if (numPackages === 0) {
        return false;
      }
      return true;
    }).property('this.model.roomPackages'),

    currentCategories: (function () {

      var categories = this.model.get('categories');
      categories = categories.toArray();
      var isComplete = true;
      var sorted = categories.sort(function (a, b) {
        if (a.get('title') === undefined || b.get('title') === undefined) {
          isComplete = false;
        }
        return a.get('sortOrder') - b.get('sortOrder');
      });

      //the categories data has not yet been loaded
      if (!isComplete) {
        return [];
      }

      console.log("****** CATEGROIES  " + this.model.get('id'));

      this.send('onCategorySelection', sorted.get('firstObject').get('id'));

      return sorted;
    }).property('this.model.isSelected', 'this.model.categories.content.@each.title')

  });
});