define('vi-di/components/simple-modal', ['exports', 'ember', 'vi-di/templates/components/simple-modal'], function (exports, _ember, _viDiTemplatesComponentsSimpleModal) {
    exports['default'] = _ember['default'].Component.extend({
        actions: {
            toggleModal: function toggleModal() {
                this.toggleProperty('enabled');
                // Joel wuz here!
            }
        },
        layout: _viDiTemplatesComponentsSimpleModal['default']

    });
});