define('vi-di/routes/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    /*
      beforeModel: function(params) {
        var DEFAULT_SALESCENTER_ID = 83;
        console.log("BEFORE MODEL " +  params.salescenter_id);
        console.log("PARAMS  " +  params);
        if( params.salescenter_id == null ) {
          console.log("transitioningTO");
          this.transitionTo('salescenters', DEFAULT_SALESCENTER_ID);
        }
      },*/

    model: function model(params) {

      console.log("START INDEX ROUTE ");

      var DEFAULT_SALESCENTER_ID = 83;

      //    var loadingController = this.controllerFor('salescenters-loading');
      //    loadingController.set('loadingMessage', 'Loading Project');

      this.transitionTo('salescenter', DEFAULT_SALESCENTER_ID);
    }

  });
});