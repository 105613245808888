define('vi-di/components/rooms/room-clearall', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ["clear-all"],

    click: function click() {
      this.sendAction('action');
    },

    showToolTipTriggered: _ember['default'].observer('this.model.salescenter.showToolTip', function () {
      console.log("ROOM CLEAR ALL SHOW TOOL TIP");

      this.get('tooltip').show();
      var me = this;

      _ember['default'].run.later(function () {
        me.get('tooltip').hide();
      }, 3000);
    })

  });
});