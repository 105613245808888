define('vi-di/controllers/salescenter/floorplan/room/category', ['exports', 'ember', 'vi-di/mixins/google-analytics'], function (exports, _ember, _viDiMixinsGoogleAnalytics) {
  exports['default'] = _ember['default'].Controller.extend(_viDiMixinsGoogleAnalytics['default'], {

    slickRef: "",
    slickReinit: 1,

    hostURL: function hostURL() {
      return this.store.adapterFor('application').get('baseImageURL');
    },

    actions: {

      onMaterialsSelected: function onMaterialsSelected(materialId) {

        //console.log("onMaterialsSelected");
        console.log("onMaterialsSelected materialId " + materialId);

        //if there are no other materials selected, then toggle one on to ensure the property overlayImages gets fired
        var materials = this.model.get('materials');

        var selectedMaterial = materials.findBy('id', materialId);
        var isCurrentlySelected = selectedMaterial.get('isSelected');
        selectedMaterial.set('isSelected', !isCurrentlySelected);

        //for the current materials in this category, assign the correct one
        materials.forEach(function (item) {
          if (item.get('id') != materialId) {
            item.set('isSelected', false);
          }
        });

        //track selected material
        this.trackEvent(selectedMaterial.get('category').get('room').get('title'), selectedMaterial.get('category').get('title'), selectedMaterial.get('title'));
      }, // end onMaterialsSelected function

      initSlick: function initSlick(slick) {
        this.set('slickRef', slick);
      }

    }, //end actions

    overlayImages: (function () {

      //  console.log('overlayImages sortOrder:  ' + this.model.get('materials').get('firstObject').get('isSelected'));

      var overlayImagesCollection = [];

      //        var currentFloorPlan = this.model.get('currentFloorPlan');
      var currentRoom = this.model.get('room'); //currentFloorPlan.get('currentRoom');

      var categories = currentRoom.get('categories');
      var materials;

      var nLengthCategories = categories.get('length');
      var category;

      overlayImagesCollection.push(this.hostURL() + currentRoom.get('baseImage'));

      //iterate through each category for the selected room
      for (var i = 0; i < nLengthCategories; i++) {

        category = categories.objectAt(i);

        materials = category.get('materials').filterBy('isSelected', true);
        if (materials.get('length') > 0) {
          overlayImagesCollection.push(this.hostURL() + materials.get('firstObject').get('overlayImage'));
        }
      }

      console.log("OVERLAY OFF");
      console.log('CURRENT ROOM PACKAGE ' + this.model.get('room').get('currentPackage'));

      return overlayImagesCollection;
    }).property('this.model.materials.content.@each.isSelected', 'this.model.room.currentPackage'),

    currentMaterialsToDisplay: (function () {

      console.log('currentMaterialsToDisplay start');

      var currentCategory = this.model;

      var materials = currentCategory.get('materials');
      materials = materials.toArray();
      var sorted = materials.sort(function (a, b) {

        return a.get('sortOrder') - b.get('sortOrder');
      });
      console.log('currentMaterialsToDisplay end ' + sorted.length);

      //reinitialize the carousel
      var slickRef = this.get('slickRef');
      var me = this;
      if (slickRef != "") {

        //fade out existing elements
        var numSlides = slickRef.slideCount;
        for (var i = 0; i < numSlides; i++) {
          slickRef.slickRemove(0);
        }

        //reinitalize slick
        _ember['default'].run.later(function () {
          me.set('slickReinit', me.get('slickReinit') + 1);
        }, 50);

        //add any new existing slider elements
        console.log(" VALUE FOR REFRESH " + this.get('slickReinit'));
      }

      return sorted;
    }).property('this.model'),

    slidesToShowValue: (function () {
      var currentCategory = this.model;
      var materials = currentCategory.get('materials');

      var MAX_TO_SCROLL = 6;
      var MIN_TO_SCROLL = 5;
      var numMaterials = materials.toArray().length;
      /*
      if (numMaterials < MAX_TO_SCROLL) {
        console.log("SLIDESTOSCROLLVALUE  " + numMaterials);
        return numMaterials;
      } */

      //on iPads, we want to only scroll 5, on other devicse, allow to scroll up to 6
      var carouselContainerWidth = $('#carouselContainer').width();
      var materialsPaneWidth = $('#materialsPane').width();

      var THUMBNAIL_WIDTH = 130;

      var numToDisplay = Math.ceil(materialsPaneWidth / THUMBNAIL_WIDTH);

      //debugger;

      if (numToDisplay >= numMaterials) {
        //reallocate the width and return accordingly
        $('#carouselContainer').css("width", "100%");
        $('#slickPrevArrow').css("display", "none");
        $('#slickNextArrow').css("display", "none");

        //      debugger;

        return Math.min(MAX_TO_SCROLL, numMaterials);
      } else {
        //reset the width and return accordingly
        $('#carouselContainer').css("width", "calc(100% - 80px)");
      }

      return Math.min(MIN_TO_SCROLL, numToDisplay);
    }).property('this.model.isSelected', 'this.model.materials', 'this.model.materials.content.@each.isSelected'),

    hostURLProperty: (function () {
      return this.store.adapterFor('application').get('baseImageURL');
    }).property('this.model.room.floorplan.salescenter')

  });
});