define('vi-di/adapters/application', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

    //export default DS.FixtureAdapter.extend();

    var ApplicationAdapter = _emberData['default'].RESTAdapter.extend({
        host: 'http://vidi.cloudapp.net:8080/',
        namespace: 'VirtualDecorator',
        baseImageURL: 'http://vidi.cloudapp.net:8080/assets/'
    });

    exports['default'] = ApplicationAdapter;
});