define('vi-di/models/room-package', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({

    title: _emberData['default'].attr('string'),
    room: _emberData['default'].belongsTo('Room', { async: true }),
    packageRules: _emberData['default'].hasMany('PackageRule', { async: true }),
    sortOrder: _emberData['default'].attr('number'),
    isSelected: _emberData['default'].attr('boolean', { defaultValue: false })

  });
});