define('vi-di/routes/salescenter', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        renderTemplate: function renderTemplate() {
            this.render('salescenter', { into: 'application' });
        },

        model: function model(params) {

            console.log("START SALES CENTER  " + params.salescenter_id);

            var SALESCENTER_ID;

            if (params.salescenter_id != null) {
                SALESCENTER_ID = params.salescenter_id;
            }
            console.log("SALESCENTER ID  " + SALESCENTER_ID);

            var HOST_URL = this.store.adapterFor('application').get('host');
            console.log("HOST_URL  " + HOST_URL);

            var loadingController = this.controllerFor('salescenters-loading');
            loadingController.set('loadingMessage', 'Loading Project');

            return this.store.query('salescenter', { salesCenterID: SALESCENTER_ID }).then(function (salescenters) {
                console.log("FINISHED SALES CENTER ");

                //salescenters.get('firstObject') = sales center object
                return salescenters.get('firstObject');
            }); //end find sales center
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
        },

        afterModel: function afterModel(model, transition) {
            console.log("Sales Center AFTER MODEL");

            //select first floor plan
            var floorPlan = model.get('floorplans').get('firstObject'); //model.get('currentFloorPlan').get('id');

            this.transitionTo('salescenter.floorplan', floorPlan.get('id'));
            return;
        }

    });
});