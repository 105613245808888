define('vi-di/components/package-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ["package_items items animated fadeInUp"],
    classNameBindings: ['isSelected:package_items_selected'],
    isSelected: false,

    click: function click() {
      var packageId = this.get('packageId');
      this.sendAction('action', packageId);
    }
  });
});